<template>
  <div class="xhsGroupBrandManage">
    <a-tabs v-model="tabKey" size="large" @change="onTabChange">
      <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.label"></a-tab-pane>
    </a-tabs>
    <template v-if="tabKey === 1">
      <a-form-model ref="form" :model="form">
        <div>
          <a-space>
            <div class="search_brand">
              <span
                :class="['all_item', !form.principalId ? 'active_item' : '', allBrandList.length ? 'brand_item' : '']"
                @click="handleBrandClick(undefined)"
                >全部品牌</span
              >
              <span
                :class="['brand_item', form.principalId === item.principalId ? 'active_item' : '']"
                v-for="item in allBrandList"
                :key="item.principalId"
                @click="handleBrandClick(item.principalId)"
                >{{ item.principalName }}</span
              >
            </div>
            <a-select
              v-model="form.groupId"
              style="width: 200px"
              placeholder="运营团队"
              show-search
              option-filter-prop="children"
              @change="getDataList(true)"
              allowClear
            >
              <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                item.groupName
              }}</a-select-option>
            </a-select>
          </a-space>
        </div>
        <div style="margin: 10px 0px; padding-right: 10px">
          <a-space size="large">
            <SearchItemList
              v-model="form.status"
              :allowNullCheck="true"
              :itemList="[
                {
                  value: 'NORMAL',
                  text: '运营中成员',
                  number: normalTotal,
                },
              ]"
              @click="getDataList(true)"
            />
            <SearchItemList
              v-model="form.status"
              :allowNullCheck="true"
              :itemList="[
                {
                  value: 'STOP',
                  text: '已停止运营',
                  number: stopTotal,
                },
              ]"
              @click="getDataList(true)"
            />
          </a-space>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div>
            <a-input-search
              style="margin-right: 15px; width: 180px"
              placeholder="搜索成员名/编码"
              v-model.trim="form.search"
              @search="getDataList"
              allowClear
            ></a-input-search>
            <a-select
              v-model="form.operatorUsernameList"
              mode="multiple"
              style="width: 200px"
              placeholder="运营人"
              show-search
              option-filter-prop="children"
              @change="getDataList(true)"
              allowClear
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorUsername"
                :value="item.operatorUsername"
                >{{ item.operatorName }}</a-select-option
              >
            </a-select>
          </div>
          <div>
            <a-button v-show="!selectedRowKeys.length" disabled>批量操作</a-button>
            <a-dropdown>
              <a-button v-show="selectedRowKeys.length">批量操作</a-button>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button type="link" @click="handleBatchSetManager">设置运营人</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    title="确定停止运营？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleSwitchMemberStatus(selectedRowKeys, 'STOP')"
                  >
                    <a-button type="link" style="color: #d9001b">停止运营</a-button>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-button type="primary" style="margin: 0 15px" @click="handleAddAccount">添加成员</a-button>
          </div>
        </div>
      </a-form-model>
    </template>
    <template v-if="tabKey === 2">
      <div style="display: flex; justify-content: space-between">
        <div>
          <a-select
            v-model="form.status"
            style="width: 200px"
            placeholder="状态"
            show-search
            option-filter-prop="children"
            @change="getDataList(true)"
            allowClear
          >
            <a-select-option value="NORMAL">正常</a-select-option>
            <a-select-option value="STOP">停止运营</a-select-option>
          </a-select>
        </div>
        <div>
          <a-button type="primary" style="margin: 0 15px" @click="showAddBrandModal = true">添加品牌</a-button>
        </div>
      </div>
    </template>

    <a-table
      style="margin-top: 10px"
      :loading="tableLoading"
      :columns="columns"
      :data-source="dataList"
      :rowKey="(record) => record.id"
      :row-selection="tabKey === 1 ? rowSelection : null"
      :pagination="false"
    >
      <div slot="runningAccount" slot-scope="record">
        <a-button type="link" @click="handleOpenPage(record)">{{
          record.accountNumber ? record.accountNumber : 0
        }}</a-button>
      </div>
      <div slot="connectData" slot-scope="record">
        <div>{{ record.contactPerson || '-' }}</div>
        <div>{{ record.contactPhoneNumber || '-' }}</div>
      </div>
      <div slot="area" slot-scope="record">{{ record.provinceName }} / {{ record.cityName }}</div>
      <div slot="gOperation" slot-scope="record">
        <template v-if="record.memberDeleteStatus === 1">
          <div style="cursor: not-allowed">已从矩阵删除</div>
        </template>
        <template v-else-if="record.status === 'STOP'">
          <!-- <a-button type="link" @click="handleSwitchMemberStatus([record.id], 'NORMAL')">恢复运营</a-button> -->
          <div style="cursor: not-allowed">停止运营</div>
        </template>
        <template v-else-if="record.memberStatus == 2">
          <div style="cursor: not-allowed">已退网</div>
        </template>
        <template v-else>
          <a-button type="link" @click="handleSetManager(record)">设置</a-button>
          <a-popconfirm
            title="绑定的媒体号也停止运营，确认停止？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleSwitchMemberStatus([record.id], 'STOP')"
          >
            <a-button type="link" style="color: #d9001b">停止运营</a-button>
          </a-popconfirm>
        </template>
      </div>
      <div slot="bOperation" slot-scope="record">
        <template v-if="record.status === 'NORMAL'">
          <a-popconfirm
            title="停运后可恢复，确认停止？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleSwitchBrandStatus(record.id, 'STOP')"
          >
            <a-button type="link" style="color: #d9001b">停止运营</a-button>
          </a-popconfirm>
        </template>
        <template v-else>
          <a-button type="link" @click="handleSwitchBrandStatus(record.id, 'NORMAL')">恢复</a-button>
        </template>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['15', '30', '50']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <!-- 添加成员弹窗 -->
    <AddAccountModalComp :allBrandList="allBrandList" ref="AddAccountModalComp" @refresh="init" />

    <!-- 添加品牌弹窗 -->
    <a-modal title="添加品牌" :visible="showAddBrandModal" @close="handleBrandClose" @cancel="handleBrandClose">
      <div>
        <span style="margin-right: 10px">品牌</span>
        <BrandSelectInput
          style="width: 200px"
          placeholder="请选择品牌"
          v-model="addBrandId"
          show-search
          option-filter-prop="children"
        />
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleBrandClose"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="addLoading"
          @click="handleAddBrand"
          :disabled="!this.addBrandId"
        >
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import SearchItemList from '@/components/xhsAgencyTool/SearchItemList';
import AddAccountModalComp from './components/AddAccountModalComp.vue';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import { groupColumns, brandColumns } from './data';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'xhsGroupBrandManage',
  props: {},
  components: {
    SearchItemList,
    AddAccountModalComp,
    BrandSelectInput,
  },
  data() {
    const tabs = [
      {
        value: 1,
        label: '成员',
      },
      {
        value: 2,
        label: '品牌',
      },
    ];
    return {
      tabs,
      tabKey: 1,
      form: {
        status: 'NORMAL',
      },
      selectedRowKeys: [],
      operatorList: [],
      allBrandList: [],
      allTeamList: [],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      stopTotal: 0,
      normalTotal: 0,
      tableLoading: false,
      addBrandId: undefined,
      showAddBrandModal: false,
      addLoading: false,
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            status: record.status,
            memberDeleteStatus: record.memberDeleteStatus,
            disabled: record.status === 'STOP' || record.memberDeleteStatus === 1 || record.memberStatus == 2,
          },
        }),
      };
    },
    columns() {
      return this.tabKey === 1 ? groupColumns : brandColumns;
    },
  },
  watch: {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getDataList(true);
      this.getAllBrandList();
      this.getOperatorList();
      this.getOperatorTeamList();
    },
    async getDataList(firstPage = false) {
      if (firstPage) this.pagination.current = 1;
      let params = {};
      if (this.tabKey === 1) {
        params = {
          ...this.form,
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };
        this.getStatistics(params);
      } else {
        params = {
          status: this.form.status,
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };
      }
      const APINAME = this.tabKey === 1 ? 'getMemberList' : 'getBrandManageList';
      this.tableLoading = true;
      const { code, data, message } = await api[APINAME](params).finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList = data.list;
        this.pagination.total = data.total;
        this.selectedRowKeys = [];
      } else {
        this.$message.error(message);
      }
    },
    handleBrandClick(value) {
      this.form.principalId = value;
      this.form.groupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.getDataList(true);
    },
    async getAllBrandList() {
      const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
      if (code === 200) {
        this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
      } else {
        this.$message.error(message);
      }
    },
    async getStatistics(params) {
      const { code, data, message } = await api.getMemberStatistics(params);
      if (code === 200) {
        this.normalTotal = data.find((v) => v.status === 'NORMAL').count;
        this.stopTotal = data.find((v) => v.status === 'STOP').count;
      } else {
        this.$message.error(message);
      }
    },
    async getOperatorList() {
      const { code, data, message } = await api.getMemberOperateList();
      if (code === 200) {
        this.operatorList = data;
      } else {
        this.$message.error(message);
      }
    },
    onTabChange() {
      this.form = {};
      this.dataList = [];
      this.init();
    },
    handleAddAccount() {
      this.$refs.AddAccountModalComp.openAddModal();
    },
    async handleSwitchMemberStatus(idList, status) {
      this.tableLoading = true;
      const { code, message } = await api
        .changeMemberStatus({
          idList,
          status,
        })
        .finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    async handleSwitchBrandStatus(id, status) {
      const params = { id, status };
      this.tableLoading = true;
      const { code, message } = await api.changeBrandStatus(params).finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    async handleAddBrand() {
      if (!this.addBrandId) {
        this.$message.error('请选择品牌');
        return;
      }
      const params = {
        principalId: this.addBrandId.split(',')[0],
        principalName: this.addBrandId.split(',')[1],
        status: 'NORMAL',
      };
      this.addLoading = true;
      const { code, message } = await api.handleAddBrand(params).finally(() => (this.addLoading = false));
      if (code === 200) {
        this.$message.success('添加成功');
        this.handleBrandClose();
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleBrandClose() {
      this.showAddBrandModal = false;
      this.addBrandId = undefined;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleSetManager(data) {
      this.$refs.AddAccountModalComp.handleSetManager([data.id], data);
    },
    handleBatchSetManager() {
      this.$refs.AddAccountModalComp.handleSetManager(this.selectedRowKeys);
    },
    handleOpenPage({ memberCode }) {
      let routeData = this.$router.resolve({
        name: 'xhsMediaList',
        query: { memberCode },
      });
      window.open(routeData.href, '_blank');
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}
</style>
