<template>
  <div class="search_list_comp">
    <span
      :class="['list_item', { active_item: value === item.value }]"
      v-for="(item, index) in itemList"
      :key="index"
      @click="handleItemClick(item)"
    >
      <span class="item_text"
        >{{ item.text }}
        <a-tooltip placement="top">
          <template slot="title">
            <div v-html="item.tips"></div>
          </template>
          <a-icon v-show="item.tips" type="question-circle" />
        </a-tooltip>
      </span>
      <span v-if="showDefaultNum" class="item_number">{{ item.number || 0 }}</span>
      <slot name="bottom" v-bind:item="item"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'searchListComp',
  props: {
    value: { default: () => '' },
    itemList: { default: () => [] },
    allowNullCheck: { default: () => false },
    showDefaultNum: { default: () => true },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    let items = document.querySelectorAll('.list_item');
    let itemsLength = items?.length || 0;
    items.forEach(function (item, index) {
      item.style.zIndex = itemsLength - index;
      if (index !== 0) {
        item.style.paddingLeft = '25px';
      }
    });
  },
  methods: {
    handleItemClick(item) {
      if (this.value === item.value) {
        if (this.allowNullCheck) {
          this.$emit('input', undefined);
          this.$nextTick(() => {
            this.$emit('click', undefined);
          });
        }
        return;
      }
      this.$emit('input', item.value);
      this.$nextTick(() => {
        this.$emit('click', item.value);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search_list_comp {
  display: flex;

  .list_item {
    display: flex;
    padding: 5px 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #b2b2b2;
    margin-right: -10px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    .item_text {
      font-size: 14px;
      color: #6f6f6f;
    }

    .item_number {
      font-size: 12px;
      font-weight: bold;
      color: #6f6f6f;
    }

    &.active_item {
      border: 1px solid #359dfd;
      color: #1890ff;
      box-shadow: 1px 0px 8px 0px #359dfd5c;

      .item_text,
      .item_number {
        color: #1890ff;
      }
    }
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
</style>
