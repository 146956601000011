export const groupColumns = [
  {
    title: '名称',
    dataIndex: 'memberName',
  },
  {
    title: '运营人',
    dataIndex: 'operatorName',
  },
  {
    title: '运营中账号',
    width: 80,
    align: 'center',
    scopedSlots: { customRender: 'runningAccount' },
  },
  {
    title: '联系人',
    width: 120,
    align: 'center',
    scopedSlots: { customRender: 'connectData' },
  },
  {
    title: '编码',
    dataIndex: 'memberCode',
  },
  {
    title: '大区',
    dataIndex: 'regionName',
  },
  {
    title: '省市',
    scopedSlots: { customRender: 'area' },
  },
  {
    title: '品牌',
    dataIndex: 'principalName',
  },
  {
    title: '操作人',
    dataIndex: 'actor',
  },
  {
    title: '操作时间',
    dataIndex: 'atime',
  },
  {
    align: 'center',
    title: '操作',
    width: 180,
    scopedSlots: { customRender: 'gOperation' },
  },
];

export const brandColumns = [
  {
    title: '品牌',
    dataIndex: 'principalName',
  },
  {
    title: '状态',
    dataIndex: 'status',
    customRender: (text) => (text === 'NORMAL' ? '正常' : '停止运营'),
  },
  {
    title: '操作时间',
    dataIndex: 'atime',
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'bOperation' },
  },
];
