<template>
  <a-select v-model="inputValue" @change="handleChange" v-bind="$attrs" v-on="$listeners">
    <a-select-option v-for="item in brandList" :key="`${item.id},${item.principalName}`">{{
      item.principalName
    }}</a-select-option>
  </a-select>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  props: ['value'],
  data() {
    return {
      inputValue: undefined,
      brandList: [],
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
  },
  methods: {
    async getBrandList() {
      try {
        const { code, data, message } = await api.getBrandList(0);
        if (code === 200) {
          this.brandList = data;
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.$message.error('操作失败');
      }
    },
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val); // 避免使用@change 父组件会触发两次
      this.$emit('onChange', val);
    },
  },
  created() {
    this.inputValue = this.value;
    this.getBrandList();
  },
};
</script>

<style scoped lang="scss"></style>
