<template>
  <div class="AccountUploadComp">
    <a-modal
      width="600px"
      :zIndex="1001"
      title="添加成员"
      :visible="showAddModal"
      @close="handleMediaClose"
      @cancel="handleMediaClose"
    >
      <div>
        <div>
          <span>品牌 <span style="color: #f00; margin-right: 15px">*</span></span>
          <a-select
            style="width: 300px"
            v-model="addMediaParams.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in allBrandList" :key="item.principalId" :value="item.principalId">{{
              item.principalName
            }}</a-select-option>
          </a-select>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 10px">
          <span>成员编码 <span style="color: #f00; margin-right: 15px">*</span></span>
          <span>已输入 {{ addCount }} 个</span>
        </div>
        <a-textarea
          v-model.trim="addMediaParams.account"
          placeholder="输入成员编码，每个成员编码一行"
          :auto-size="{ minRows: 15, maxRows: 15 }"
        ></a-textarea>
        <div>1、支持复制粘贴方式新增，每行一个成员编码</div>
        <div>2、每次最多添加 100 行</div>
        <div>3、已添加过的成员会自动忽略</div>
        <div>4、自动读取聚金塔中成员的联络人和手机号，后续可修改</div>
      </div>

      <template slot="footer">
        <a-button key="back" @click="handleMediaClose"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="addLoading" @click="handleAdd"> 确定 </a-button>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      :zIndex="1001"
      title="批量添加结果"
      :visible="showFailModal"
      @close="showFailModal = false"
      @cancel="showFailModal = false"
    >
      <div style="color: #f00" v-if="failData.failList && failData.failList.length">
        <a-icon type="close-circle" style="margin-right: 6px; color: #f00" />导入失败 {{ failData.failList.length }} 个
        没有识别到成员，请检查
        <div>
          <span v-for="(v, i) in failData.failList">{{ v }}{{ i === failData.failList.length - 1 ? '' : '，' }}</span>
        </div>
      </div>
      <div style="color: #333333" v-if="failData.existList && failData.existList.length">
        <a-icon type="info-circle" theme="filled" style="margin-right: 6px; color: #f59a23" />已在运营
        {{ failData.existList.length }} 个，自动忽略
        <span v-for="(v, i) in failData.existList">{{ v }}{{ i === failData.existList.length - 1 ? '' : '，' }}</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleFailNext">{{ idList.length ? '下一步' : '确认' }}</a-button>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      :zIndex="1001"
      :title="`已选${idList.length}个成员，设置`"
      :visible="showSetManageModal"
      @close="handleShowSetClose"
      @cancel="handleShowSetClose"
    >
      <div>
        <span>运营人</span>
        <a-select
          v-model="updateData.operatorId"
          style="margin-left: 85px; width: 300px"
          placeholder="运营人"
          show-search
          option-filter-prop="children"
          allowClear
        >
          <a-select-option v-for="item in allUserList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div style="margin-top: 15px; color: #555555">设置成员及其名下小红书账号的负责人</div>
      <template v-if="showContact">
        <div>
          <span>门店小红书联系人</span>
          <a-input
            v-model.trim="updateData.contactPerson"
            allowClear
            style="margin: 10px 0 10px 15px; width: 300px"
            placeholder="请填写联系人"
          >
          </a-input>
        </div>
        <div>
          <span>手机号</span>
          <a-input
            v-model.trim="updateData.contactPhoneNumber"
            allowClear
            style="margin-left: 85px; width: 300px"
            placeholder="请填写手机号"
          >
          </a-input>
        </div>
      </template>
      <template slot="footer">
        <a-button @click="handleShowSetClose">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="addLoading"
          :disabled="!updateData.operatorId"
          @click="handleSetConfirm"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AccountUploadComp',
  props: {
    allBrandList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      // 添加小红书号
      showAddModal: false,
      addMediaParams: {
        principalId: undefined,
        account: undefined,
      },
      failData: {
        existList: [],
        failList: [],
      },
      showFailModal: false,
      principalList: [],
      addLoading: false,
      showSetManageModal: false,
      updateData: {
        operatorId: undefined,
        contactPerson: undefined,
        contactPhoneNumber: undefined,
      },
      idList: [],
      allUserList: [],
      showContact: false,
    };
  },
  computed: {
    addCount() {
      return this.addMediaParams.account ? this.addMediaParams.account.split('\n').filter((v) => v).length : 0;
    },
  },
  watch: {},
  created() {
    this.getAllUserList();
  },
  mounted() {},
  methods: {
    async getAllUserList() {
      const { code, message, data } = await api.getAllUserList();
      if (code === 200) {
        this.allUserList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    openAddModal() {
      this.showAddModal = true;
      this.showContact = false;
    },
    handleMediaClose() {
      this.showAddModal = false;
      this.showContact = false;
      this.addMediaParams = {
        principalId: undefined,
        account: undefined,
      };
    },
    async handleAdd() {
      const params = {
        principalId: this.addMediaParams.principalId,
        operatorName: this.$router.app?.user?.name || undefined,
        operatorUsername: this.$router.app?.user?.username || undefined,
        memberCodeList: this.addMediaParams.account?.split('\n')?.filter((v) => v) || [],
      };
      if (!params.principalId) return this.$message.error('请选择品牌');
      if (params.memberCodeList.length === 0) return this.$message.error('请至少输入一个账号');
      if (params.memberCodeList.length > 100) return this.$message.error('一次最多添加100个账号');
      this.addLoading = true;
      const { data, code, message } = await api.handleAddMember(params).finally(() => (this.addLoading = false));
      if (code === 200) {
        this.handleMediaClose();
        this.idList = data.successList;
        if (this.idList && this.idList.length > 0) {
          this.$message.success('添加成功');
          this.$emit('refresh');
        }
        if (data.existList?.length || data.failList?.length) {
          this.failData = data;
          this.showFailModal = true;
          return;
        }
        this.showSetManageModal = true;
      } else {
        this.$message.error(message);
      }
    },
    handleFailNext() {
      this.showFailModal = false;
      this.handleSetManager();
    },
    handleSetManager(idList = [], allData = {}) {
      if (idList?.length) this.idList = idList;
      if (this.idList && this.idList.length > 0) {
        this.showSetManageModal = true;
        if (this.idList.length === 1) {
          this.showContact = true;
          if (allData.contactPerson) this.updateData.contactPerson = allData.contactPerson;
          if (allData.contactPhoneNumber) this.updateData.contactPhoneNumber = allData.contactPhoneNumber;
          if (allData.operatorName && allData.operatorUsername) {
            const find = this.allUserList.find(
              (v) => v.name === allData.operatorName && v.username === allData.operatorUsername
            );
            if (find?.id) this.updateData.operatorId = find.id;
          }
        } else {
          this.showContact = false;
        }
      }
    },
    async handleSetConfirm() {
      const { username, name } = this.allUserList.find((v) => v.id === this.updateData.operatorId) || {};
      const params = {
        idList: this.idList,
        status: 'NORMAL',
        operatorName: name,
        operatorUsername: username,
        contactPhoneNumber: null,
        contactPerson: null,
      };
      if (this.showContact) {
        params.contactPerson = this.updateData.contactPerson ? this.updateData.contactPerson : '';

        if (this.updateData.contactPhoneNumber) {
          const reg = /^1[2|3|4|5|6|7|8][0-9]\d{8}$/;
          if (!reg.test(this.updateData.contactPhoneNumber)) {
            this.$message.error('请填写正确的手机号');
            return;
          }
          params.contactPhoneNumber = this.updateData.contactPhoneNumber;
        } else {
          params.contactPhoneNumber = '';
        }
      }
      this.addLoading = true;
      const { code, message } = await api.changeMemberStatus(params).finally(() => (this.addLoading = false));
      if (code === 200) {
        this.$message.success('设置成功');
        this.handleShowSetClose();
        this.$emit('refresh');
      } else {
        this.$message.error(message);
      }
    },
    handleShowSetClose() {
      this.showSetManageModal = false;
      this.idList = [];
      this.updateData = {
        operatorId: undefined,
        contactPerson: undefined,
        contactPhoneNumber: undefined,
      };
    },
  },
};
</script>

<style lang="less" scoped></style>
